import React, { useState, useEffect } from "react";
import { Typography, Box, IconButton, TextField, Button, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../lib/theme";
import TrackItem from "./TrackItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { db } from "../../shared/firebase";
import { doc, updateDoc, collection } from "firebase/firestore";

const TrackList = ({
  tracks,
  isPlaying,
  playingTrackId,
  handlePlayPause,
  currentTime,
  duration,
  handleSeek,
  user,
  userId,
  sharedWith,
  isRecording,
  recordingTrackId,
  selectedTrackId,
  stopRecording,
  startRecording,
  editingTrackId,
  editingTrackTitle,
  handleEditTrack,
  handleSaveTrackTitle,
  handleCancelEdit,
  setEditingTrackTitle,
  subscriptionLevel,
  handleDeleteTrack,
  handlePause,
  isAddingTrack,
  setIsAddingTrack,
  newTrackTitle,
  setNewTrackTitle,
  handleAddTrack,
  albumId,
  showTrackControls = true,
  remainingTime,
  playingSegmentIndex,
  handleDeleteSegment,
  setTracks,
}) => {
  const [showInfoAlert, setShowInfoAlert] = useState(true);

  useEffect(() => {
    const alertClosed = localStorage.getItem('trackInfoAlertClosed');
    if (alertClosed) {
      setShowInfoAlert(false);
    }
  }, []);

  const handleCloseInfoAlert = () => {
    setShowInfoAlert(false);
    localStorage.setItem('trackInfoAlertClosed', 'true');
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddTrack();
    }
  };

  const updateTrackOrder = async (albumId, tracks) => {
    const albumRef = doc(db, "albums", albumId);

    // Update the album document with the reordered tracks array
    await updateDoc(albumRef, { tracks });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedTracks = Array.from(tracks);
    const [movedTrack] = reorderedTracks.splice(result.source.index, 1);
    reorderedTracks.splice(result.destination.index, 0, movedTrack);

    // Update orderIndex for each track
    const updatedTracks = reorderedTracks.map((track, index) => ({
      ...track,
      orderIndex: index,
    }));

    // Update the order in Firebase
    await updateTrackOrder(albumId, updatedTracks);

    // Update the state with the new order
    setTracks(updatedTracks);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { xs: "100%", sm: "auto" } }}>
      {showInfoAlert && (
        <Alert
          severity="info"
          sx={{ mb: 4 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseInfoAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>Welcome to the Album!</strong> Here's where collaborators can record answers for any track within this album. Each track can have multiple recordings! Just press record to add another recording to each track.
        </Alert>
      )}
      <Typography variant="h5" sx={{ color: colors.gold, mb: 2 }}>
        Tracks
      </Typography>
      {tracks.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="trackList">
            {(provided) => (
              <ol
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ padding: 0, margin: 0 }}
              >
                {tracks.map((track, index) => (
                  <Draggable key={track.id} draggableId={track.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TrackItem
                          albumId={albumId}
                          currentTime={currentTime}
                          duration={duration}
                          editingTrackId={editingTrackId}
                          editingTrackTitle={editingTrackTitle}
                          handleCancelEdit={handleCancelEdit}
                          handleDeleteTrack={handleDeleteTrack}
                          handleEditTrack={handleEditTrack}
                          handlePlayPause={handlePlayPause}
                          handlePause={handlePause}
                          handleSaveTrackTitle={handleSaveTrackTitle}
                          handleSeek={handleSeek}
                          isPlaying={isPlaying}
                          isRecording={isRecording}
                          key={track.id || index}
                          playingTrackId={playingTrackId}
                          recordingTrackId={recordingTrackId}
                          setEditingTrackTitle={setEditingTrackTitle}
                          selectedTrackId={selectedTrackId}
                          sharedWith={sharedWith}
                          showTrackControls={showTrackControls}
                          startRecording={startRecording}
                          stopRecording={stopRecording}
                          track={track}
                          user={user}
                          userId={userId}
                          subscriptionLevel={subscriptionLevel}
                          remainingTime={remainingTime}
                          playingSegmentIndex={playingSegmentIndex}
                          handleDeleteSegment={handleDeleteSegment}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ol>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Typography sx={{ color: colors.white }}>
          No tracks available
        </Typography>
      )}
      {user && (user.uid === userId || sharedWith.includes(user.uid)) && (
        <Box mt={2}>
          {isAddingTrack ? (
            <Box display="flex" alignItems="center">
              <TextField
                value={newTrackTitle}
                onChange={(e) => setNewTrackTitle(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter track title"
                size="small"
                sx={{ mr: 1, flexGrow: 1 }}
              />
              <IconButton onClick={handleAddTrack} sx={{ color: colors.white }}>
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={() => setIsAddingTrack(false)}
                sx={{ color: colors.white }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <>
              <Button
                startIcon={<AddIcon />}
                // disabled={subscriptionLevel < 1 && tracks.length === 5}
                onClick={() => setIsAddingTrack(true)}
                variant="secondary"
                sx={{ mt: 4 }}
              >
                Add a New Track
                {/* {subscriptionLevel < 1 &&
                  tracks.length === 5 &&
                  " (Limit 5 for free users)"} */}
              </Button>
              {/* {subscriptionLevel < 1 && tracks.length === 5 && (
                <Alert severity="info" sx={{ mt: 2 }}>
                  You've reached the track limit for free users. Upgrade to add unlimited tracks and albums!
                  Visit your <Link to="/profile" style={{ color: colors.black, textDecoration: "underline" }}>profile</Link> to upgrade!
                </Alert>
              )} */}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TrackList;
